import { Injectable } from '@angular/core';
import { AuthApiService } from '@app/core/api/auth/auth-api.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, Observable, of, tap } from 'rxjs';

import { AuthTokenStateActions } from './auth-token.state.actions';

export interface AuthTokenStateModel {
  accessToken: string | null;
  refreshToken: string | null;
}

const EMPTY_STATE: AuthTokenStateModel = {
  accessToken: null,
  refreshToken: null,
};

@State<AuthTokenStateModel>({
  name: 'tokens',
  defaults: { ...EMPTY_STATE },
})
@Injectable()
export class AuthTokenState {
  constructor(private readonly authApi: AuthApiService) {}

  @Selector()
  public static accessToken(state: AuthTokenStateModel): string | null {
    return state.accessToken;
  }

  @Selector()
  public static refreshToken(state: AuthTokenStateModel): string | null {
    return state.refreshToken;
  }

  @Selector()
  public static authenticated(state: AuthTokenStateModel): boolean {
    return !!state.accessToken;
  }

  @Action(AuthTokenStateActions.Login)
  public login(
    { patchState }: StateContext<AuthTokenStateModel>,
    { token, refreshToken }: AuthTokenStateActions.Login,
  ): void {
    patchState({ accessToken: token, refreshToken });
  }

  @Action(AuthTokenStateActions.Refresh)
  public refresh({ getState, setState, dispatch }: StateContext<AuthTokenStateModel>): Observable<unknown> {
    const state = getState();

    if (!state.refreshToken) {
      setState({
        refreshToken: null,
        accessToken: null,
      });
      return of(null);
    }

    return this.authApi.refresh({ refresh: state.refreshToken }).pipe(
      tap((response) =>
        setState({
          refreshToken: response.data.refresh.refresh,
          accessToken: response.data.refresh.token,
        }),
      ),
      catchError(() => dispatch(AuthTokenStateActions.Logout)),
    );
  }

  @Action(AuthTokenStateActions.Logout)
  public logout({ setState, getState }: StateContext<AuthTokenStateModel>): void {
    const state = getState();
    const wasAuthorized = !!state.accessToken;

    setState({ ...EMPTY_STATE });

    if (wasAuthorized) {
      window.location.reload();
    }
  }
}
