export enum Models {
  Brand = 'BrandModel',
  Brands = 'BrandsModel',
  Partner = 'PartnerModel',
  Partners = 'PartnersModel',
  User = 'UserModel',
  Users = 'UsersModel',
  Tariff = 'TariffModel',
  Tariffs = 'TariffsModel',
  SimCard = 'SimCardModel',
  Customer = 'CustomerModel',
  Customers = 'CustomersModel',
  Device = 'DeviceModel',
  Devices = 'DevicesModel',
  DeviceTests = 'DeviceTests',
  LastPacket = 'LastPacketModel',
  StatisticsInfo = 'StatisticsInfoModel',
  TripsInfo = 'TripsInfoModel',
  DeviceInfo = 'DeviceInfoModel',
  Firmware = 'FirmwareModel',
  TripsDailyInfo = 'TripsDailyInfoModel',
  DeviceSendCommandResponse = 'DeviceSendCommandResponseModel',
  Vehicle = 'VehicleModel',
  Vehicles = 'VehiclesModel',
  CommandLogs = 'CommandLogsModel',
  VehicleSendCommandResponse = 'VehicleSendCommandResponseModel',
  Ownership = 'OwnershipModel',
  Ownerships = 'OwnershipsModel',
  Invoice = 'InvoiceModel',
  Invoices = 'InvoicesModel',
  Fine = 'FineModel',
  Fines = 'FinesModel',
  Ckad = 'CkadModel',
  Ckads = 'CkadsModel',
  Facility = 'FacilityModel',
  Facilities = 'FacilitiesModel',
  FacilityType = 'FacilityTypeModel',
  FacilityTypes = 'FacilityTypesModel',
  FacilityOrder = 'FacilityOrderModel',
  FacilityOrders = 'FacilityOrdersModel',
  Provider = 'ProviderModel',
  Providers = 'ProvidersModel',
  FacilityOrderPayments = 'FacilityOrderPaymentsModel',
  Ticket = 'TicketModel',
  Tickets = 'TicketsModel',
  Subscription = 'SubscriptionModel',
  Subscriptions = 'SubscriptionsModel',
  SubscriptionPayment = 'SubscriptionPayment',
  SubscriptionPayments = 'SubscriptionPayments',
  Payment = 'Payment',
  Payments = 'Payments',
  Story = 'StoryModel',
  Stories = 'StoriesModel',
  CatalogRecord = 'CatalogRecordModel',
  CatalogRecords = 'CatalogRecordsModel',
  Color = 'ColorModel',
  Colors = 'ColorsModel',
  Configuration = 'ConfigurationModel',
  Configurations = 'ConfigurationsModel',
  Equipment = 'EquipmentModel',
  Equipments = 'EquipmentsModel',
  Generation = 'GenerationModel',
  Generations = 'GenerationsModel',
  Model = 'ModelModel',
  Models = 'ModelsModel',
  GenerationColor = 'GenerationColorModel',
  GenerationColors = 'GenerationColorsModel',
  EventsHistory = 'EventsHistoryModel',
  Notification = 'NotificationModel',
  Notifications = 'NotificationsModel',
  Glossary = 'GlossaryModel',
  Glossaries = 'GlossariesModel',
  UploadsHistory = 'UploadsHistory',
  All = 'all',
  NotificationTemplate = 'NotificationTemplateModel',
  Report = 'Report',
  Reports = 'Reports',
  NotificationTemplates = 'NotificationTemplatesModel',
}
