import { I18n } from '@store/backend-common';

import { DeviceType, EDeviceModel } from '../control-center';

export type GradientCoordinateValue = 0 | 1;

export type GradientCoordinates = {
  x: GradientCoordinateValue;
  y: GradientCoordinateValue;
};

export type LabelGradientConfig = {
  colors: string[];
  start: GradientCoordinates;
  end: GradientCoordinates;
};

export type LabelConfig = {
  text: string;
  color: string;
  gradient: LabelGradientConfig;
};

export type TariffEntity = {
  /** Идентификатор */
  readonly id: string;
  readonly partnerId: string | null;
  /** Название тарифа */
  name: I18n;
  /** Описание тарифа */
  description: string | null;
  /** Активность сущности (safe delete) */
  active: boolean;
  /** Длительность тарифа в днях */
  durationDays: number;
  /** Стоимость тарифа (копейки) */
  price: number;
  /** Необходимый тип устройства для тарифа */
  deviceType: DeviceType | null;
  /** Необходимая модель устройства для тарифа */
  deviceModel: EDeviceModel | null;
  /** Дата начала доступности тарифа */
  startDate: Date;
  /** Дата окончания доступности тарифа */
  endDate: Date | null;
  /** Доступность тарифа пользователям */
  available: boolean;
  /** Является  бесплатным тарифом */
  isFree: boolean;
  /** Тариф для двухнедельных подписок */
  isWithoutSubscription: boolean;
  /** Дата создания */
  readonly createdAt: Date;
  /** Дата обновления */
  updatedAt: Date;
  label: LabelConfig;
  labelConfig: Record<string, LabelConfig> | null;
  nameConfig: Record<string, I18n>;
};
