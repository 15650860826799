import { EReportStatus, EReportType } from './enums';

export interface AagrReportParams {
  from: Date;
  to: Date;
}

export type ReportParams = AagrReportParams;

export type ReportEntity = {
  /** Идентификатор */
  readonly id: string;
  /** Идентификатор (VIN) авто */
  fileUrl: string | null;
  /** Статус активации */
  status: EReportStatus;
  /** Статус активации */
  type: EReportType;
  /** Параметры генерации отчета */
  params: ReportParams;
  /** id запросившего отчет */
  requesterId: string;
  /** Почта, куда отправить письмо */
  emails: string[];
  /** Дата создания */
  readonly createdAt: Date;
  /** Дата обновления */
  updatedAt: Date;
};
