import { DeviceTestVehicleModelEntity } from '../device-test-vehicle-model.entity';

export interface DeviceTestReadModelInterface {
  currentSettings: number | null;

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  currentBeepMode: 0 | 1 | 2 | null;

  vehicleModel: DeviceTestVehicleModelEntity | null;

  currentVehicleModel: DeviceTestVehicleModelEntity | null;

  equipmentCode: string | null;
}
