export type DeviceTestVehicleModelEntity = {
  /** Код комплектации */
  equipmentCode: string;

  /** Код модели авто для блока Misos */
  modelCode: number;

  /** Код модели авто для блока Misos */
  //eslint-disable-next-line @typescript-eslint/no-magic-numbers
  beepSetting: 0 | 1 | 2 | null;

  /** Описание комплектации */
  description: string | null;

  /** Дата создания */
  readonly createdAt: Date;

  /** Дата обновления */
  updatedAt: Date;
};
