import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';
import { SKIP_TOKEN_INTERCEPTOR_HEADER } from '@app/core/auth/consts/skip-token-interceptor-header.const';
import { MutationActivateUserArgs, UserActivateInput } from '@app/core/graphql-global-types';
import { Apollo, gql, MutationResult } from 'apollo-angular';
import { Observable } from 'rxjs';

import { LoginRequestDto, LoginResponseDto, MeQueryResponseDto, RefreshTokenRequestDto } from './dtos';

@Injectable({ providedIn: 'root' })
export class AuthApiService {
  constructor(private readonly apollo: Apollo) {}

  public login(input: LoginRequestDto): Observable<ApolloQueryResult<{ login: LoginResponseDto }>> {
    // language=GraphQL
    const query = gql<{ login: LoginResponseDto }, { input: LoginRequestDto }>(`
      query ($input: AuthLoginInput!) {
        login(input: $input) {
          token
          refresh
        }
      }
    `);

    return this.apollo.query({
      query,
      variables: {
        input,
      },
    });
  }

  public refresh(input: RefreshTokenRequestDto): Observable<ApolloQueryResult<{ refresh: LoginResponseDto }>> {
    // language=GraphQL
    const query = gql<{ refresh: LoginResponseDto }, { input: RefreshTokenRequestDto }>(`
      query Refresh($input: AuthRefreshInput!) {
        refresh(input: $input) {
          token
          refresh
        }
      }
    `);

    return this.apollo.query({
      query,
      variables: {
        input,
      },
      context: {
        headers: new HttpHeaders().append(SKIP_TOKEN_INTERCEPTOR_HEADER, ''),
      },
    });
  }

  public me(): Observable<ApolloQueryResult<{ me: MeQueryResponseDto }>> {
    // language=GraphQL
    const query = gql<{ me: MeQueryResponseDto }, void>(`
      query {
        me {
          phone,
          email,
          firstName,
          lastName,
          patronymic,
          role,
          status
        }
      }
    `);

    return this.apollo.query({
      query,
      fetchPolicy: 'network-only',
    });
  }

  public activateUser(input: UserActivateInput): Observable<MutationResult<void>> {
    // language=GraphQL
    const mutation = gql<void, MutationActivateUserArgs>(`
      mutation ActivateUser($input: UserActivateInput!) {
        activateUser(input: $input)
      }
    `);

    return this.apollo.mutate({
      mutation,
      variables: {
        input,
      },
    });
  }
}
