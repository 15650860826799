export enum Project {
  TES = 'tes',
  Livan = 'livan',
  Kaiyi = 'kaiyi',
  Jaecoo = 'jaecoo',
  Exeed = 'exeed',
  BAIC = 'baic',
  AGR = 'agr',
  AAGR = 'aagr',
}
