import { EntityName } from '@store/backend-entity';

import { Models, ReportAction, UserRole } from '../../enums';

export const reportPermissions: Record<
  UserRole,
  { actions: Array<keyof typeof ReportAction>; subject: Models; fields?: Array<EntityName> }[]
> = {
  [UserRole.Admin]: [
    {
      actions: [ReportAction.read, ReportAction.create, ReportAction.update, ReportAction.delete],
      subject: Models.Report,
    },
    { actions: [ReportAction.read], subject: Models.Reports },
  ],

  [UserRole.Operator]: [
    {
      actions: [ReportAction.read, ReportAction.create, ReportAction.update],
      subject: Models.Report,
    },
    { actions: [ReportAction.read], subject: Models.Reports },
  ],

  [UserRole.OperatorWithoutPBAccess]: [
    {
      actions: [ReportAction.read, ReportAction.create, ReportAction.update, ReportAction.delete],
      subject: Models.Report,
    },
    { actions: [ReportAction.read], subject: Models.Reports },
  ],

  [UserRole.DeviceOperator]: [],
  [UserRole.OperatorReadAccess]: [
    { actions: [ReportAction.read], subject: Models.Report },
    { actions: [ReportAction.read], subject: Models.Reports },
  ],
};
